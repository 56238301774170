.footer {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: #212126;
  text-align: center;
  padding: 10px;

  .twitter-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
  }

  .copyright {
    margin: 0;
    font-size: 14px;
    color: #969ca2;
  }
}
